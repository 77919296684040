import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Work() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div
      className="w-full py-8 relative"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/web-development-company-services-bg.webp)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
      />
      <div className="relative w-full sectionlayout poppins z-10">
        <h1
          style={{ fontSize: "clamp(22px,2.5vw,42px)" }}
          className="text-white uppercase font-bold text-center"
          data-aos="fade-up"
        >
          Expert Web Development Solutions
        </h1>
        <p
          className="text-[#F8F5F0] text-center py-2 md:w-[80%] max-w-[750px] md:tracking-wider  mx-auto"
          data-aos="fade-up"
        >
          From startups to enterprises, we deliver custom website solutions that
          drive growth and enhance user experience. Our development expertise
          spans across multiple technologies and platforms.
        </p>

        <div className="flex lg:flex-row flex-col items-center my-10 space-y-12 lg:space-y-0 md:space-x-16">
          <div className="flex justify-center lg:w-[60%] lg:justify-right">
            <img
              src={`
${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/expert-web-development-company-dubai.webp
                `}
              alt="Laptop"
              className="rounded-[9px] max-h-[85vh] object-fit max-w-full"
              data-aos="fade-up"
            />
          </div>
          <div className="lg:flex w-full lg:w-[40%]">
            <ul
              className="grid grid-cols-1 sm:grid-cols-2 lg:space-y-4 font-bold lg:grid-cols-1 text-[#F8F5F0] 
              md:grid-cols-2 "
              style={{ fontSize: "clamp(20px,1.2vw,24px)" }}
              data-aos="fade-up"
            >
              <li className="flex py-2 ">
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/tick.webp`}
                  alt="tick"
                  className="w-[22px] mt-[15px] mr-2 h-[22px]"
                />
                <span className="border-b-2 border-[#F8AB45]">
                  <h3> Custom Website Design </h3>
                </span>
              </li>
              <li className="flex py-2 items-center md:pl-16">
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/tick.webp`}
                  alt="tick"
                  className="w-[22px] mr-2"
                />
                <span className="border-b-2 border-[#F8AB45]">
                  <h3> E-commerce Solutions </h3>
                </span>
              </li>
              <li className="flex py-2 items-center ">
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/tick.webp`}
                  alt="tick"
                  className="w-[22px] mr-2"
                />
                <span className="border-b-2 border-[#F8AB45]">
                  <h3>SEO Development </h3>
                </span>
              </li>
              <li className=" flex py-2 items-center md:pl-16">
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/tick.webp`}
                  alt="tick"
                  className="w-[22px] mr-2"
                />{" "}
                <span className="border-b-2 border-[#F8AB45]">
                  <h3> Responsive Design </h3>
                </span>
              </li>
              <li className=" flex py-2 items-center ">
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/tick.webp`}
                  alt="tick"
                  className="w-[22px] mr-2"
                />{" "}
                <span className="border-b-2 border-[#F8AB45]">
                  <h3>Website Maintenance </h3>
                </span>
              </li>
              <li className=" flex py-2 items-center md:pl-16">
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/tick.webp`}
                  alt="tick"
                  className="w-[22px] mr-2 "
                />{" "}
                <span className="border-b-2 border-[#F8AB45]">
                  UI/UX Design
                </span>
              </li>
              <li className=" flex py-2 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/tick.webp`}
                  alt="tick"
                  className="w-[22px] mr-2"
                />{" "}
                <span className="border-b-2 border-[#F8AB45]">
                  <h3>Web Optimization </h3>
                </span>
              </li>
              <li className=" flex py-2 items-center md:pl-16">
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/tick.webp`}
                  alt="tick"
                  className="w-[22px] mr-2"
                />{" "}
                <span className="border-b-2 border-[#F8AB45]">
                  <h3>Progressive Web Apps </h3>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
