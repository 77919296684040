import React, { useState, useEffect } from 'react';
import { marked } from 'marked';


const TermsAndConditions = () => {
  const [content, setContent] = useState('');

  useEffect(() => {

    const markdownContent = `
# TERMS AND CONDITIONS

## Dynamic Leo Information Technology LLC

### 1. SERVICE AGREEMENT
When clients engage our services, they agree to provide all necessary materials, content, and feedback in a timely manner. Project timelines are dependent on client responsiveness. Delays in client feedback may result in project timeline adjustments.

### 2. PAYMENT TERMS
All projects require a 50% upfront payment to commence work. The remaining 50% is due upon project completion but before the website goes live. Additional features requested after the initial agreement will be quoted separately.

### 3. REFUND POLICY
We understand the importance of client satisfaction while protecting our business interests. Our refund policy is structured as follows:

#### During Development Phase:
* Cancellation within 48 hours of payment: 70% refund.
* No refunds applicable for cancellation after 48 hours of payment.

#### Non-Refundable Items:
* Domain registration fees
* SSL certificate costs
* Third-party software licenses
* Administrative fees

### 4. REVISION POLICY
Each package includes a specified number of revision rounds. Additional revisions will be quoted according to the scope of revision. Major changes to approved designs or functionality may incur additional charges.

### 5. PROJECT ABANDONMENT
Projects inactive for 30 days due to client non-responsiveness will be considered abandoned. No refund will be issued, and reactivation will require a new contract.

### 6. DELIVERY TIMEFRAMES
Standard delivery timeframes are coordinated to the customers through project proposals. These timeframes assume timely client feedback and content delivery.

### 7. MAINTENANCE AND RENEWAL
Annual renewal fees cover domain renewal, hosting, SSL certificate, and basic maintenance. Renewal fees must be paid **30 days before expiration** to ensure uninterrupted service.

### 8. INTELLECTUAL PROPERTY
Upon full payment, clients receive:
* Full rights to their website design and content
* Access to source files only subject to front-end design of the website and doesn't include underlying backend business logics
* Admin credentials

**We retain the right to:**
* Use the website in our portfolio
* Include our credit link unless otherwise negotiated

### 9. WARRANTY PERIOD
We provide a 30-day warranty period after website launch to address any bugs or technical issues related to our development work. This doesn't cover:
* Client-requested changes
* Third-party software issues
* Content updates
* Server downtime beyond our control

### 10. DISPUTE RESOLUTION
Any disputes will first undergo mandatory mediation before legal action. UAE laws govern this agreement, with Dubai courts having exclusive jurisdiction.

### 11. LIMITATION OF LIABILITY
Our liability is limited to the amount paid for services. We're not liable for:
* Lost profits or data
* Consequential damages
* Third-party claims
* Content accuracy

### 12. TERMINATION
We reserve the right to terminate services if:
* Client violates these terms
* Payment is overdue by 30 days
* Client engages in illegal, unethical activities or unprofessional behavior with the team
    `;

    setContent(marked(markdownContent));
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 list-disc">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-md p-8 text-justify">
          <div 
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;