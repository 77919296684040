const Clients = [
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/srtip.webp`, alt: "Logo 1" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/3.webp`, alt: "Logo 3" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/4.webp`, alt: "Logo 4" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/srtip.webp`, alt: "Logo 5" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/5.webp`, alt: "Logo 6" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/7.webp`, alt: "Logo 8" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/8.webp`, alt: "Logo 9" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/9.webp`, alt: "Logo 10" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/10.webp`, alt: "Logo 11" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/11.webp`, alt: "Logo 12" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/12.webp`, alt: "Logo 13" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/13.webp`, alt: "Logo 14" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/14.webp`, alt: "Logo 15" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/15.webp`, alt: "Logo 16" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/16.webp`, alt: "Logo 17" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/17.webp`, alt: "Logo 18" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/18.webp`, alt: "Logo 19" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/19.webp`, alt: "Logo 20" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/20.webp`, alt: "Logo 21" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/21.webp`, alt: "Logo 22" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/22.webp`, alt: "Logo 23" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/6.webp`, alt: "Logo 7" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/al_mubarak_transport.webp`, alt: "Logo 24" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/Genius Trading.webp`, alt: "Logo 25" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/nt gift logo.webp`, alt: "Logo 26" },
  { img: `${process.env.PUBLIC_URL}/Assets/Clients/Logo-RightOne.webp`, alt: "Logo 27" },
];

export default Clients;
