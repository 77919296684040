import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const HeaderWithBackgroundImage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div className=" relative min-h-[90vh] flex justify-center items-center py-8">
      <div className="sectionlayout">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/Hero/software-maintenance-services-hero.webp)`,
          }}
        ></div>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div
          className="relative md:w-[90%] lg:w-[75%] mx-auto  z-10 text-center text-[#F8F5F0]"
          data-aos="fade-up"
        >
          <h1
            className="font-bold uppercase poppins"
            style={{ fontSize: "clamp(28px,3vw,50px)" }}
          >
        Professional {" "} <span className=" text-[#F8AB45]"> Software Maintenance </span>{" "} & IT Support Solutions
          </h1>
          <h2
            className="mt-4 md:w-[90%] mx-auto "
            style={{ fontSize: "clamp(18px,1.5vw,30px)" }}
          >
          Keep your systems running at peak performance with expert software maintenance and 24/7 technical support. From proactive monitoring to rapid issue resolution, we ensure your business operations stay smooth
          </h2>
          <Link to="/contact" onClick={handleNav}>
            <button
              onClick={handleNav}
              className="mt-6 px-6 py-3 text-white mx-auto rounded-md shadow-lg transition duration-300"
              style={{
                background: "linear-gradient(90deg, #607C8A 0%, #BEBEC2 100%)",
              }}
            >
             Get Support Now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithBackgroundImage;
