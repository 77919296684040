

const DomainandHosting = [
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/DomainHosting/domain-registration-service-icon.webp`,
        title: "Domain Registration",
        alt:"Domain Registration",
        descrition: "Premium domain registration services for UAE businesses. Secure your digital identity with extensive TLD options and advanced domain management tools. Build your brand with expert domain strategy."

    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/DomainHosting/cloud-hosting-service-use-icon.webp`,
        title: "Cloud Web Hosting",
        alt:"Web Hosting",
        descrition: "High-performance cloud hosting powered by AWS and CloudFlare. Choose from shared, VPS, or dedicated solutions with 99.99% uptime guarantee and CDN optimization for global reach."

    },
   
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/DomainHosting/website-migration-uae-service-icon.webp`,
        title: "Website Migration",
        alt:"Website Migration",
        descrition: "Zero-downtime website migration with expert technical handling. Seamless transfer of databases, files, and configurations to our secure hosting infrastructure."
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/DomainHosting/ssl-security-service-icon.webp`,
        title: "SSL Security",
        alt:"SSL Security",
        descrition: "Advanced SSL certificate implementation for secure data transmission. Boost SEO rankings and customer trust with industry-standard encryption and security protocols."

    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/DomainHosting/business-email-hosting-icon.webp`,
        title: "Business Email Hosting",
        alt:"Business Email Hosting",
        descrition: "Professional email solutions with custom domain integration. Enterprise-grade security, anti-spam protection, and scalable storage for seamless business communication."

    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/DomainHosting/technical-support-service-icon.webp`,
        title: "Technical Support",
        alt:"support",
        descrition:" 24/7 expert support team for immediate assistance. Dedicated technical specialists ensuring optimal performance and rapid issue resolution."
    }
]

export default DomainandHosting;