import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const HeaderWithVideoBackground = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div className="montserrat relative min-h-[90vh] flex justify-center items-center">
      <div className="mx-auto max-w-[1640px] p-[1rem] lg:p-[2rem]">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src={`${process.env.PUBLIC_URL}/Assets/Hero/digital-transformation-it-solutions-company-uae.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        <div
          className="relative md:w-[90%] lg:w-[75%]  mx-auto z-10 text-center text-[#F8F5F0]"
          data-aos="fade-up"
        >
          <h1
            className=" font-bold"
            style={{ fontSize: "clamp(28px,3vw,50px)" }}
          >
             Leading Digital Transformation & IT Solutions {" "}
            <span className="border-b-[3px] border-[#F8AB45]">
              {" "}
              in Dubai
            </span>{" "}
          
          </h1>
          <h2
            className="mt-4 "
            style={{ fontSize: "clamp(18px,1.5vw,30px)" }}
          >
           Empowering UAE businesses with professional software development and digital innovations. Your trusted technology partner for scalable enterprise solutions across Dubai and GCC
          </h2>
          <Link to="/contact" onClick={handleNav}>
          <button
            onClick={handleNav}
            className="mt-6 px-6 py-3  text-white rounded-md shadow-lg transition duration-300"
            style={{
              background: "linear-gradient(90deg, #607C8A 0%, #BEBEC2 100%)",
            }}
          >
          Get Free Consultation
          </button>
            </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithVideoBackground;
