import React from "react";
import Hero from "./Hero";
import Work from "./Work";
import BelowSection from "./BelowSection";
import { Helmet } from "react-helmet";

function AppDevelopment() {
  return (
    <>
      <Helmet>
        <title>
          Best Mobile App Development Company Dubai | iOS & Android Apps UAE
        </title>
        <meta
          name="description"
          content="Leading mobile app development company in Dubai. Expert iOS & Android app developers creating custom mobile solutions. Transform your business with professional app development services in UAE"
        />

        <script type="application/ld+json">
          {`
   {
 "@context": "https://schema.org",
 "@type": "WebPage",
 "name": "Mobile App Development Services Dubai",
 "description": "Leading mobile app development company in Dubai. Expert iOS & Android app developers creating custom mobile solutions. Transform your business with professional app development services in UAE.",
 "provider": {
   "@type": "Organization",
   "name": "Dynamic Leo",
   "url": "https://dynamicleo.ae",
   "image": "https://dynamicleo.ae/images/mobile-app-development-company-dubai-hero.webp",
   "address": {
     "@type": "PostalAddress",
     "addressLocality": "Dubai",
     "addressCountry": "UAE"
   },
   "aggregateRating": {
     "@type": "AggregateRating",
     "ratingValue": "4.9",
     "reviewCount": "50",
     "bestRating": "5"
   }
 },
 "mainEntity": {
   "@type": "Service",
   "name": "Mobile App Development",
   "serviceType": "Mobile Application Development",
   "areaServed": {
     "@type": "GeoCircle",
     "geoMidpoint": {
       "@type": "GeoCoordinates",
       "latitude": "25.2048",
       "longitude": "55.2708"
     }
   },
   "offers": {
     "@type": "Offer",
     "itemOffered": [
       {
         "@type": "Service",
         "name": "Native iOS & Android Development",
         "description": "Expert mobile app development for both platforms, ensuring high performance, security, and seamless functionality."
       },
       {
         "@type": "Service",
         "name": "User-Centric Design & Interface",
         "description": "Creating engaging mobile experiences with intuitive UI/UX design."
       },
       {
         "@type": "Service",
         "name": "Advanced App Security & Testing",
         "description": "Comprehensive testing protocols ensuring your app's security, performance, and reliability."
       },
       {
         "@type": "Service",
         "name": "Continuous App Support",
         "description": "24/7 maintenance services, regular updates, and technical support."
       },
       {
         "@type": "Service",
         "name": "App Store Optimization",
         "description": "Strategic ASO implementation to maximize your app's visibility."
       }
     ]
   },
   "hasOfferCatalog": {
     "@type": "OfferCatalog",
     "name": "Mobile App Development Process",
     "itemListElement": [
       {
         "@type": "OfferCatalog",
         "name": "Strategic Planning & Analysis",
         "description": "Comprehensive analysis of requirements and market opportunities"
       },
       {
         "@type": "OfferCatalog",
         "name": "Advanced Market Research",
         "description": "In-depth competitor analysis and market trend evaluation"
       },
       {
         "@type": "OfferCatalog",
         "name": "Interactive Prototyping",
         "description": "Creating detailed wireframes and clickable prototypes"
       },
       {
         "@type": "OfferCatalog",
         "name": "Expert Development & Design",
         "description": "Professional UI/UX design with robust development"
       },
       {
         "@type": "OfferCatalog",
         "name": "Comprehensive Testing",
         "description": "Rigorous quality assurance across devices"
       },
       {
         "@type": "OfferCatalog",
         "name": "Launch & Optimization",
         "description": "Strategic app launch with continuous monitoring"
       }
     ]
   }
 },
 "image": [
   "https://dynamicleo.ae/images/mobile-app-development-company-dubai-hero.webp",
   "https://dynamicleo.ae/images/mobile-app-services-background.webp",
   "https://dynamicleo.ae/images/custom-mobile-app-development-uae-services.webp",
   "https://dynamicleo.ae/images/mobile-app-development-process-workflow.webp"
 ],
 "breadcrumb": {
   "@type": "BreadcrumbList",
   "itemListElement": [{
     "@type": "ListItem",
     "position": 1,
     "name": "Home",
     "item": "https://dynamicleo.ae"
   },
   {
     "@type": "ListItem", 
     "position": 2,
     "name": "Services",
     "item": "https://dynamicleo.ae/services"
   },
   {
     "@type": "ListItem",
     "position": 3,
     "name": "Mobile App Development",
     "item": "https://dynamicleo.ae/services/mobile-app-development"
   }]
 }
}
  `}
        </script>
      </Helmet>
      <Hero />
      <Work />
      <BelowSection />
    </>
  );
}

export default AppDevelopment;
