import React, { useEffect } from "react";
import Home from "../../Data/Solutions/Home";
import AOS from "aos";
import "aos/dist/aos.css";


function Solutions() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div
      className="w-full bg-cover  bg-no-repeat poppins"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/Home/software-services-showcase-background.webp)`,
      }}
    >
      <div className="w-full sectionlayout  py-16">
        <h2
          className="font-bold  pt-12 mb-6   text-white text-center w-full  mx-auto"
          style={{ fontSize: "clamp(34px,2.4vw,45px)" }}
        >
        Premium Software Development & Digital Solutions
        </h2>
        <h3 className=" text-[#DCDAD5] text-center  text-[18px] lg:text-[20px] font-medium">
        End-to-end technology services providing custom solutions for business excellence. From web development to AI integration, we deliver innovation that drives growth
        </h3>
        <div className=" grid grid-cols-1 md:grid-cols-2 px-1 gap-8 py-16">
          {Home.map((service, index) => (
            <div
              key={index}
              className="sm:flex bg-white shadow-lg rounded-lg overflow-hidden"
              data-aos="fade-up"
            >
              <div className="sm:w-[36%]" data-aos="zoom-in">
                <img
                  src={service.img}
                  alt={service.alt}
                  className="object-cover w-full h-full"
                />
              </div>
              <div
                className="sm:w-[64%] p-4 md:p-6   "
                data-aos="fade-left"
              >
                <h2
                  className=" font-bold text-[#3E4E41] my-2 "
                  style={{ fontSize: "clamp(24px,1.8vw,30px)" }}
                >
                  {service.title}
                </h2>
                <p
                  className="text-[#766047]  mb-4"
                  style={{ fontSize: "clamp(18px,0.9vw,18px)" }}
                >
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Solutions;
