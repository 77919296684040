
const BelowSect = [
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/User Centered Research.webp`,
        title: "Discovery & Analysis",
        alt:"Discovery & Analysis",
        descrition: "Step 1"

    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/Planning.webp`,
        title: "Strategic Planning",
        alt:"Strategic Planning",
        descrition: "Step 2"

    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/UIUX Design.webp`,
        title: "UI/UX Design",
        alt:"UI/UX Design",
        descrition: "Step 3"
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/Front-End Development.webp`,
        title: "Frontend Development",
        alt:"Front-End Development",
        descrition: "Step 4"
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/Back-End Development.webp`,
        title: "Backend Development",
        alt:"Back-End Development",
        descrition: "Step 5"

    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/Integration & Deployment.webp`,
        title: "Testing & Integration",
        alt:"Testing & Integration",
        descrition: "Step 6"

    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/WebDevelopment/Launch.webp`,
        title: "Successful Launch",
        alt:"Successful Launch",
        descrition: "Step 7"
    }
]

export default BelowSect;