import React from 'react'
function Social() {
    return (
        <div className='flex flex-row'>
            <a href='https://www.linkedin.com/company/dynamicleo/' target='_blank' rel='noopener noreferrer' className='border rounded-[50%] flex p-3 mr-5'>
                <img src={`${process.env.PUBLIC_URL}/Assets/icons/social/linkedin.svg`} alt="LinkedIn" />
            </a>
            <a href='https://www.facebook.com/people/Dynamic-Leo/61564848668886/?mibextid=qi2Omg&rdid=WHXn2gAkb9V2HX05&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fjwwtg6hxUG2zozoj%2F%3Fmibextid%3Dqi2Omg' target='_blank' rel='noopener noreferrer' className='border rounded-[50%] flex p-3 mr-5'>
                <img src={`${process.env.PUBLIC_URL}/Assets/icons/social/facebook.svg`} alt="Facebook" />
            </a>
            <a href='https://www.instagram.com/dynamicleo_uae?igsh=MXY5ZTM0MzBxN3FpNA==' target='_blank' rel='noopener noreferrer' className='border rounded-[50%] flex p-3'>
                <img src={`${process.env.PUBLIC_URL}/Assets/icons/social/instagram.svg`} alt="Instagram" />
            </a>
        </div>
    )
}

export default Social;
