import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import DigitalMarketingWork from "../../../Data/Solutions/DigitalMarketingWork";

const Work = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const { DMWork, DMWork2 } = DigitalMarketingWork;

  return (
    <div
      className="w-full bg-cover bg-no-repeat poppins"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/solutions/DigitalMarketing/digital-marketing-services-background.webp)` }}
    >
      <div style={{ backgroundColor: "#00000080" }}>
        <div className="w-full sectionlayout py-16">
          <h2
            className="font-bold montserrat uppercase drop-shadow-md pt-12 mb-6 text-center  text-white lg:w-[80%] w-full mx-auto relative"
            style={{ fontSize: "clamp(34px,2.4vw,45px)" }}
          >
           Full-Spectrum   <span className="relative text-[#FF9B48] ">Digital Marketing</span>{" "}
           Solutions
          </h2>
          <p className="text-white lg:w-[74%] text-center  mx-auto text-[18px] lg:text-[20px] font-medium">
          Discover our comprehensive range of digital marketing services designed to boost your online presence and drive sustainable growth. From search engine optimization to social media management, we deliver strategies that convert.
          </p>

          <div className="space-y-4 md:space-y-20 py-12">
            <div className="grid grid-cols-1  md:grid-cols-3 gap-x-4">
              <div className="flex flex-col space-y-4 h-full">
                {DMWork.slice(1, 3).map((item, index) => (
                  <div
                    className="relative group "
                    key={index}
                    data-aos="fade-up"
                  >
                    <img
                      className="w-full h-[270px] md:h-[100%] object-cover"
                      src={item.img}
                      alt={item.alt}
                    />
                    <div className="absolute inset-0 bg-black ow-full h-full object-cover opacity-40 group-hover:opacity-70 transition-opacity duration-300"></div>
                    <div className="absolute inset-0 flex flex-col justify-end items-start lg:pl-4 lg:pr-7  text-white px-4 transition-all duration-500">
                      <h2 className="text-sm xl:text-lg font-bold  transform group-hover:-translate-y-4 transition-all duration-500 border-b-[2px] border-[#F8AB45] pb-1">
                        {item.title}
                      </h2>
                      <p className="opacity-0  ease-in-out  transform translate-y-full group-hover:mb-2 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 text-sm xl:text-lg">
                        {item.descrition}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="relative col-span-1 mt-4 lg:mt-0 md:col-span-2 lg:col-span-2 row-span-2 group"
                data-aos="fade-up"
              >
                <img
                  className="w-full md:h-[100%]  object-cover h-[270px]"
                  src={DMWork[0].img}
                  alt={DMWork[0].alt}
                />
                <div className="absolute inset-0 bg-black opacity-40 group-hover:opacity-70 transition-opacity duration-300"></div>
                <div className="absolute inset-0 flex flex-col justify-end items-start lg:pl-4 lg:pr-7  text-white p-4 transition-all duration-500">
                  <h2 className="text-sm xl:text-lg border-b-[2px] border-[#F8AB45] pb-1 font-bold md:mb-[-20px] group-hover:mb-[-10px] transform group-hover:-translate-y-4 transition-all duration-500">
                    {DMWork[0].title}
                  </h2>
                  <p className="opacity-0 transform translate-y-full group-hover:mb-2  group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 text-sm xl:text-lg">
                    {DMWork[0].descrition}
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-y-4 md:gap-y-0 md:grid-cols-3 gap-x-4">
              <div
                className="relative col-span-1 md:col-span-2 lg:col-span-2 row-span-2 group h-full"
                data-aos="fade-up"
              >
                <img
                  className="w-full md:h-[100%] h-[270px] object-cover"
                  src={DMWork2[0].img}
                  alt={DMWork2[0].alt}
                />
                <div className="absolute inset-0 bg-black opacity-40 group-hover:opacity-70 transition-opacity duration-300"></div>
                <div className="absolute inset-0 flex flex-col justify-end items-start text-white p-4 transition-all duration-500">
                  <h2 className="text-sm xl:text-lg font-bold md:mb-[-20px] group-hover:mb-[-10px] transform group-hover:-translate-y-4 transition-all duration-500 border-b-[2px] border-[#F8AB45] pb-1">
                    {DMWork2[0].title}
                  </h2>
                  <p className="opacity-0 transform translate-y-full group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 text-sm xl:text-lg">
                    {DMWork2[0].descrition}
                  </p>
                </div>
              </div>
              <div className="md:flex items-center flex-col space-y-6 h-full">
                {DMWork2.slice(1, 3).map((item, index) => (
                  <div
                    className="relative group "
                    key={index}
                    data-aos="fade-up"
                  >
                    <img
                      className="w-full h-[270px] md:h-full lg:min-h-[330px] "
                      src={item.img}
                      alt={item.alt}
                    />
                    <div className="absolute inset-0 bg-black opacity-40 group-hover:opacity-70 transition-opacity duration-300"></div>
                    <div className="absolute inset-0 flex flex-col justify-end items-start text-white p-4 transition-all duration-500">
                      <h2 className="text-sm xl:text-lg font-bold md:mb-[-40px] group-hover:mb-[-10px] transform group-hover:-translate-y-4 transition-all duration-500 border-b-[2px] border-[#F8AB45] pb-1">
                        {item.title}
                      </h2>
                      <p className="opacity-0 transform translate-y-full group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 text-sm xl:text-lg">
                        {item.descrition}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
