

const AppDevelopment = [
    {
        title: "Native iOS & Android Development",
        des:"Expert mobile app development for both platforms, ensuring high performance, security, and seamless functionality. Custom solutions tailored to your business requirements with cutting-edge technologies."
    },
    {
        title: "User-Centric Design & Interface",
        des:"Creating engaging mobile experiences with intuitive UI/UX design. Our designers focus on user journey optimization, interactive elements, and conversion-focused interfaces."
    },
    {
        title: "Advanced App Security & Testing",
        des:"Comprehensive testing protocols ensuring your app's security, performance, and reliability. From functionality testing to security audits, we deliver bug-free mobile solutions."
    }, 
    {
        title: "Continuous App Support",
        des:"24/7 maintenance services, regular updates, and technical support to ensure optimal app performance. Proactive monitoring and instant issue resolution for uninterrupted user experience."
    },
    {
        title: "App Store Optimization",
        des:"Strategic ASO implementation to maximize your app's visibility on App Store and Google Play. Data-driven techniques to boost downloads, rankings, and user acquisition."
    }
    
]

export default AppDevelopment;