import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);

  return (
    <div className="montserrat relative min-h-[90vh] flex justify-center items-center overflow-hidden">
      <div className="sectionlayout" style={{ fontSize: "clamp(14px,0.8vw,16px)" }}>
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/About/aboutbg.webp)`,
          }}
        ></div>
        <div
          className="absolute inset-0"
          style={{ backgroundColor: "background: rgba(62, 78, 65, 0.4)" }}
        ></div>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative  mt-16 text-white items-center lg:flex z-10">
          <h1
            className="font-bold uppercase drop-shadow-md md:w-1/2"
            style={{ fontSize: "clamp(30px,3.4vw,65px)" }}
            data-aos="fade-right"
          >
            About <br /> <span className="text-[#F8AB45]"> Dynamic leo </span>
          </h1>
          <p
            className="mt-5 lg:mt-0 leading-loose font-medium md:w-1/2"
            style={{ fontSize: "clamp(16px,1.1vw,22px)" }}
            data-aos="fade-left"
          >
            At Dynamic Leo, we excel in the digital space by forming close
            partnerships with our clients and delivering customized IT
            solutions. We focus on understanding each client’s unique needs,
            fostering strong, trust-based relationships, and utilizing
            innovative technology to drive their success and help them reach
            their full potential.
          </p>
        </div>
        <div className="flex  flex-col my-20 items-center">
          <h1
            className="font-bold text-center  text-white uppercase drop-shadow-md "
            style={{ fontSize: "clamp(30px,3.4vw,65px)" }}
            data-aos="fade-up"
          >
            Meet our <span className="text-[#F8AB45]"> Ceo </span>
          </h1>
          <img
            src={`${process.env.PUBLIC_URL}/Assets/About/Faraz.webp`}
            alt="Faraz"
            className="h-[250px] mt-5 z-10 md:h-[300px] "
            data-aos="fade-up"
          />
          <h6
            className="font-semibold poppins z-10  text-[18px] md:text-[23px] text-white text-center mt-3"
            data-aos="fade-up"
          >
            Mian Faraz Sandhal
          </h6>
          <p
            className="text-[15px] z-10 md:text-[17px] text-white poppins text-center mb-3"
            data-aos="fade-up"
          >
            Chief Executive Officer & IT Pioneer
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
