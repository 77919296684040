import React from 'react'
import Hero from './Hero'
import Work from './Work'
import BelowSection from './BelowSection'
import { Helmet } from 'react-helmet'

function Aibased() {
    return (
        <>
         <Helmet>
        <title>Dynamic Leo: Expert AI & Data Science Solutions in Dubai | Business Growth</title>
        <meta name="description" content="Boost your business with Dynamic Leo's cutting-edge AI solutions in Dubai. Our expert data science team delivers AI integration, model development, NLP, chatbots & more. Trusted by industries like retail, healthcare, finance in UAE." />
      </Helmet>
            <Hero />
            <Work />
            <BelowSection />

        </>
    )
}

export default Aibased;