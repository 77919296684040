import React from 'react'
import Hero from './Hero'
import Work from './Work'
import { Helmet } from 'react-helmet';

function Maintanance() {
    return (
        <>
         <Helmet>
        <title>Best Digital Marketing Agency UAE | SEO & Social Media Services Dubai</title>
        <meta name="description" content="Premier digital marketing services in UAE. Expert SEO, social media, content marketing, PPC advertising & email campaigns for Dubai businesses. Data-driven strategies with proven ROI." />
       
        <script type="application/ld+json">
  {`
    {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Digital Marketing Services",
      "provider": {
        "@type": "Organization",
        "name": "Dynamic Leo",
        "url": "https://dynamicleo.ae",
        "image": "https://dynamicleo.ae/images/digital-marketing-services-uae-hero.webp",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Dubai",
          "addressCountry": "UAE"
        }
      },
      "serviceType": "Digital Marketing",
      "areaServed": {
        "@type": "GeoCircle",
        "geoMidpoint": {
          "@type": "GeoCoordinates",
          "latitude": 25.2048,
          "longitude": 55.2708
        },
        "geoRadius": 50000
      },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Digital Marketing Services",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Social Media Marketing",
              "description": "Strategic social media management and advertising services",
              "url": "https://dynamicleo.ae/services/social-media-marketing"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Search Engine Optimization",
              "description": "Professional SEO services for higher rankings",
              "url": "https://dynamicleo.ae/services/seo"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Pay Per Click Advertising",
              "description": "Targeted PPC campaigns and management",
              "url": "https://dynamicleo.ae/services/ppc"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Content Marketing",
              "description": "Strategic content creation and marketing",
              "url": "https://dynamicleo.ae/services/content-marketing"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Email Marketing",
              "description": "Professional email campaign management",
              "url": "https://dynamicleo.ae/services/email-marketing"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Analytics and Reporting",
              "description": "Comprehensive performance tracking and analysis",
              "url": "https://dynamicleo.ae/services/analytics-reporting"
            }
          }
        ]
      },
      "review": {
        "@type": "Review",
        "reviewBody": "Excellent digital marketing services!",
        "author": {
          "@type": "Person",
          "name": "John Doe"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "4.9",
          "bestRating": "5"
        }
      }
    }
  `}
        </script>


      </Helmet>
            <Hero />
            <Work />
        </>
    )
}

export default Maintanance