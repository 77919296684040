const DMWork = [
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/Maintanance/server-management-service.webp`,
    title: "Server Management",
    alt: "Server Management",
    descrition: " 24/7 server optimization and security management with proactive monitoring. Regular updates, performance tuning, and security patches ensuring maximum uptime.",
  },
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/Maintanance/access-control-security.webp`,
    title: "Access Management",
    alt: "Access Management",
    descrition: "Advanced user authentication and access control systems for enhanced security. Multi-factor authentication and privileged access management protecting critical assets.",
  },
  
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/Maintanance/cloud-infrastructure-solutions.webp`,
    title: "Cloud Infrastructure",
    alt: "Cloud Infrastructure",
    descrition: "Secure cloud storage and infrastructure management powered by enterprise solutions. Scalable architecture with automated backups and seamless accessibility.",
  },

]
const DMWork2 = [
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/Maintanance/network-security-monitoring-icon.webp`,
    title: "Network Security",
    alt: "Network Security",
    descrition: "Real-time network monitoring and threat detection with instant response protocols. Comprehensive security analysis and protection against cyber threats.",
  },
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/Maintanance/disaster-recovery-backup-icon.webp`,
    title: "Disaster Recovery",
    alt: "Disaster Recovery",
    descrition: "Enterprise backup solutions and robust disaster recovery planning. Automated data backup systems with rapid recovery capabilities ensuring business continuity.",
  },

  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/Maintanance/system-administration-support-icon.webp`,
    title: "System Administration",
    alt: "System Administration",
    descrition: "Professional system configuration and performance optimization services. Expert management of user permissions, system updates, and technical operations.",
  },
];

const digitalMarketingWorks = { DMWork, DMWork2 };

export default digitalMarketingWorks;
