import React from "react";
import Hero from "./Hero";
import Work from "./Work";
import BelowSection from "./BelowSection";
import { Helmet } from "react-helmet";

function WebDevelopment() {
  <Helmet>
    <title>
      Best Website Development Company Dubai | Custom Web Solutions UAE
    </title>
    <meta
      name="description"
      content="Professional website development services in Dubai. Custom web solutions, e-commerce development, and responsive design. Transform your business with expert web developers in UAE."
    />

    <script type="application/ld+json">
      {`
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Professional Website Development Services Dubai",
  "description": "Professional website development services in Dubai. Custom web solutions, e-commerce development, and responsive design. Transform your business with expert web developers in UAE.",
  "provider": {
    "@type": "Organization",
    "name": "Dynamic Leo",
    "url": "https://dynamicleo.ae",
    "image": "https://dynamicleo.ae/images/custom-website-development-hero-uae.webp",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Dubai",
      "addressCountry": "UAE"
    }
  },
  "mainEntity": {
    "@type": "Service",
    "name": "Website Development Services",
    "serviceType": "Web Development",
    "areaServed": {
      "@type": "GeoCircle",
      "geoMidpoint": {
        "@type": "GeoCoordinates",
        "latitude": "25.2048",
        "longitude": "55.2708"
      }
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Web Development Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Custom Website Design",
            "description": "Professional custom website design services"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "E-commerce Solutions",
            "description": "Custom e-commerce website development"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "SEO Development",
            "description": "Search engine optimized web development"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Responsive Design",
            "description": "Mobile-friendly responsive web design"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Website Maintenance",
            "description": "Ongoing website maintenance and support"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "UI/UX Design",
            "description": "User interface and experience design"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Web Optimization",
            "description": "Website performance optimization services"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Progressive Web Apps",
            "description": "PWA development and implementation"
          }
        }
      ]
    },
    "workPerformed": {
      "@type": "ItemList",
      "name": "Web Development Process",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Discovery & Analysis"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Strategic Planning"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "UI/UX Design"
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": "Frontend Development"
        },
        {
          "@type": "ListItem",
          "position": 5,
          "name": "Backend Development"
        },
        {
          "@type": "ListItem",
          "position": 6,
          "name": "Testing & Integration"
        },
        {
          "@type": "ListItem",
          "position": 7,
          "name": "Successful Launch"
        }
      ]
    }
  },
  "review": {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "4.9",
      "bestRating": "5"
    }
  },
  "image": [
    "https://dynamicleo.ae/images/custom-website-development-hero-uae.webp",
    "https://dynamicleo.ae/images/web-development-company-services-bg.webp",
    "https://dynamicleo.ae/images/expert-web-development-company-dubai.webp",
    "https://dynamicleo.ae/images/website-development-workflow-process.webp"
  ]
   } `}
    </script>
  </Helmet>;
  return (
    <>
      <Hero />
      <Work />
      <BelowSection />
    </>
  );
}

export default WebDevelopment;
