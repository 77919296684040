import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


function BelowSection() {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);

  const colorchange = ["Data Science", "Communication", "Methodology", "Brand Exposure", "Client-Centric", "Innovation"];
  
  const data = [
    {
      heading: "Retail",
      des: "Developing intelligent chatbots for enhanced customer support, implementing visual search capabilities for improved product discoverability, and creating personalized product recommendation systems to boost sales and customer satisfaction."
    },
    {
      heading: "Healthcare",
      des: "Deploying AI-powered diagnostic tools for early disease detection, utilizing natural language processing (NLP) to extract insights from medical records, and implementing AI-assisted robotic surgery systems for enhanced precision and reduced complications."
    },
    {
      heading: "Finance",
      des: "Building AI-driven fraud detection systems to identify and prevent financial crimes, developing algorithmic trading strategies for improved investment performance, and implementing AI-powered credit scoring models for more accurate risk assessment. "
    },
    {
      heading: "Manufacturing",
      des: "Implementing predictive maintenance systems to reduce equipment downtime, utilizing computer vision for quality control and defect detection, and optimizing production processes through AI-driven supply chain management. "
    },
    {
      heading: "Agriculture",
      des: "Deploying AI-powered crop monitoring systems for early disease detection and yield optimization, implementing precision agriculture techniques using AI and IoT sensors, and developing AI-driven weather forecasting models for better crop planning. "
    },
    {
      heading: "Energy",
      des: "Utilizing AI for predictive maintenance of power grids and renewable energy systems, implementing AI-driven energy demand forecasting for efficient resource allocation, and developing intelligent energy management systems for optimized consumption and reduced carbon footprint."
    }
  ];
  const applyColorToHeading = (heading) => {
    let coloredHeading = heading;
    colorchange.forEach((keyword) => {
      const regex = new RegExp(`(${keyword})`, "gi"); 
      coloredHeading = coloredHeading.replace(
        regex,
        `<span style="color: #F8AB45">$1</span>`
      );
    });
    return coloredHeading;
  };

  return (
    <div className="w-full py-4 md:py-8 h-full relative" style={{ 
      backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/solutions/AiBased/ai-ml-applications-industries-dubai.webp)`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
    }}>
      <div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.74)" }} />
      <div className="relative w-full sectionlayout poppins z-10">
        <div data-aos="fade-up">
          <h2 
            style={{ fontSize: 'clamp(28px,2vw,34px)' }} 
            className='text-white text-center pt-10 drop-shadow-md uppercase font-bold'
            data-aos="fade-up"
          >
           Major  <span className="text-[#F8AB45]">AI Applications </span> in Industries Served by Dynamic Leo
          </h2>
          <p 
            className='text-[#F8F5F0] text-center font-light py-2 md:tracking-wider'
            style={{ fontSize: 'clamp(17px,1.1vw,21px)' }}
            data-aos="fade-up"
          >
         Dynamic Leo has successfully implemented AI solutions across various industries. Some of the major AI applications in these industries include:
          </p>
        </div>
        <div className='mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
          {data.map((item, i) => (
            <div key={i} className='px-4 py-6 border-2 border-white rounded-[8px]' 
            data-aos="fade-up"
            >
              <h2
                style={{ fontSize: "clamp(21px,1.3vw,26px)" }}
                className="text-[#F8AB45] tex-center drop-shadow-md uppercase font-bold"
                dangerouslySetInnerHTML={{ __html: applyColorToHeading(item.heading) }} 
              />
              <p
                className="text-[#F8F5F0] font-light pt-3 text-left md:tracking-wider"
                style={{ fontSize: "clamp(14px,0.9vw,17px)" }}
              >
                {item.des}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BelowSection;
