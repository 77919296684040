import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Solutions from '../Solutions/Solutions';
import Clients from './Clients';

function Home() {
  return (
    <>
      <Helmet>
        <title> Leading Software Development & IT Solutions Company Dubai | Dynamic Leo</title>
        <meta
          name="description"
          content="Premier software development company in Dubai offering web & mobile app development, digital marketing, AI solutions, hosting, and IT support. Transform your business with our professional tech solutions."
        />
       
       
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Dynamic Leo",
            "url": "https://dynamicleo.ae",
            "logo": "https://dynamicleo.ae/images/logo.png",
            "description": "Leading software development and IT solutions company in Dubai",
            "address": {
              "@type": "PostalAddress",
              "addressCountry": "UAE",
              "addressLocality": "Dubai"
            },
            "sameAs": [
              "https://www.linkedin.com/company/dynamicleo",
              "https://www.facebook.com/dynamicleo",
              "https://twitter.com/dynamicleo"
            ],
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://dynamicleo.ae"
            },
            "offers": {
              "@type": "AggregateOffer",
              "offers": [
                {
                  "@type": "Offer",
                  "name": "Website Development",
                  "description": "Custom website development and web solutions"
                },
                {
                  "@type": "Offer",
                  "name": "Mobile App Development",
                  "description": "iOS and Android application development"
                },
                {
                  "@type": "Offer",
                  "name": "Digital Marketing",
                  "description": "Strategic digital marketing and SEO services"
                },
                {
                  "@type": "Offer",
                  "name": "AI & ML Solutions",
                  "description": "Advanced AI and machine learning solutions"
                },
                {
                  "@type": "Offer",
                  "name": "Domain & Hosting",
                  "description": "Premium web hosting and domain management"
                },
                {
                  "@type": "Offer",
                  "name": "Software Maintenance",
                  "description": "Professional software maintenance and support"
                }
              ]
            }
          })}
        </script>
      </Helmet>

      <Hero />
      <Solutions />
      <Clients />
      {/* <Reviews /> */}
    </>
  );
}

export default Home;
