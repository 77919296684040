import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function BelowSection() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const data = [
    {
      heading: "Strategic Planning & Analysis",
      des: "Comprehensive analysis of your requirements, market opportunities, and target audience to create winning app strategies.",
    },
    {
      heading: "Advanced Market Research",
      des: "In-depth competitor analysis, market trend evaluation, and user behavior studies to position your app for success. ",
    },
    {
      heading: "Interactive Prototyping",
      des: "Creating detailed wireframes and clickable prototypes to visualize user flows and app functionality before development.",
    },
    {
      heading: "Expert Development & Design",
      des: "Professional UI/UX design combined with robust development using latest technologies and coding standards.",
    },
    {
      heading: "Comprehensive Testing",
      des: "Rigorous quality assurance across devices and platforms ensuring flawless performance and user experience.",
    },
    {
      heading: "Launch & Optimization",
      des: "Strategic app launch with continuous monitoring, updates, and performance optimization based on real user feedback.",
    },
  ];
  return (
    <div
      className="w-full py-8 h-full  relative"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/solutions/AppDevelopment/mobile-app-development-process-workflow.webp)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.74)" }}
      />
      <div className="relative w-full md:flex  md:space-x-16 sectionlayout poppins z-10">
        <div
          className="md:sticky flex flex-col items-start md:top-[30%] md:w-1/2 md:h-[360px]"
          data-aos="fade-up"
        >
          <h2
            style={{ fontSize: "clamp(28px,2vw,34px)" }}
            className=" text-white  pt-10 drop-shadow-md uppercase font-bold"
            data-offset="-50"
          >
            Professional  {" "}
            <span className=" text-[#F8AB45]">Mobile App </span>{" "} Development Process
          </h2>
          <p
            className="text-[#F8F5F0] font-light py-2  md:tracking-wider "
            style={{ fontSize: "clamp(17px,1.1vw,21px)" }}
          >
            Experience mobile app excellence from concept to launch. Our proven development methodology ensures high-quality applications that meet market demands and exceed user expectations.
          </p>
        </div>
        <div className="md:w-1/2 md:flex items-end flex-col">
          <div className="mt-6 lg:w-[80%]">
            {data.map((item, i) => (
              <>
                <div key={i} className="py-4  " data-aos="fade-up">
                  <div className="flex space-x-2">
                    <img
                      src={`${process.env.PUBLIC_URL}/Assets/solutions/AppDevelopment/circle.webp`}
                      alt="Circle"
                      className="h-[22px] mt-[14px]"
                    />
                    <h3
                      style={{ fontSize: "clamp(20px,1.4vw,26px)" }}
                      className="font-bold text-white"
                    >
                      {item.heading}
                    </h3>
                  </div>
                  <p
                    className="text-[#F8F5F0] font-light mt-2"
                    style={{ fontSize: "clamp(14px,1.1vw,20px)" }}
                  >
                    {item.des}
                  </p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BelowSection;
