import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import data from "../../../Data/Solutions/Aibased";
function Work() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div
      className="w-full py-8 h-full  relative"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/solutions/AiBased/ai-services-business-growth-dubai..webp)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.74)" }}
      />
      <div className="relative w-full md:flex   md:space-x-8 sectionlayout poppins z-10">
        <div
          className="md:sticky flex flex-col items-start md:top-[50%] md:w-[40%]  md:h-[360px]"
          data-aos="fade-up"
        >
          <h2
            style={{ fontSize: "clamp(28px,2vw,34px)" }}
            className=" text-white  pt-10 drop-shadow-md uppercase font-bold"
          >
           Custom AI Solutions for {" "}
            <span className=" text-[#F8AB45]">Business Growth </span>
          </h2>
          <p
            className="text-[#F8F5F0] font-light py-2  md:tracking-wider "
            style={{ fontSize: "clamp(17px,1.1vw,21px)" }}
          >
            Transform your operations with powerful AI technologies. Our expert solutions drive efficiency and innovation.
          </p>
        </div>
        <div className=" md:flex items-end flex-col  md:w-[60%]">
          <div className="md:mt-28 mt-6  gap-4 grid md:grid-cols-1 lg:grid-cols-2">
            {data.map((item, i) => (
              <>
                <div
                  key={i}
                  className="py-4 px-4 border-2 border-white rounded-[8px] "
                  data-aos="fade-up"
                >
                  <div className="flex justify-center items-center flex-col md:grid ">
                    <img src={item.img} alt={item.alt} className="pt-2" />
                    <h2
                      style={{ fontSize: "clamp(22px,1.4vw,26px)" }}
                      className=" text-[#F8AB45]  pt-8 drop-shadow-md uppercase font-bold"
                    >
                      {item.title}
                    </h2>
                    <p
                      className="text-[#F8F5F0] font-light py-2 text-center md:text-left   md:tracking-wider "
                      style={{ fontSize: "clamp(15px,1vw,17px)" }}
                    >
                      {item.descrition}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
