import React from 'react'
import Hero from './Hero'
import Work from './Work'
import { Helmet } from 'react-helmet'

function DomainHosting() {
    return (
        <>
        <Helmet>
        <title>
        IT Support & Software Maintenance Services | Technical Solutions Dubai
        </title>
        <meta
          name="description"
          content="24/7 software maintenance and IT support services in Dubai. Server management, cloud infrastructure, network security and expert system administration solutions"
        />

        <script type="application/ld+json">
          {`
   {
 "@context": "https://schema.org",
 "@type": "WebPage",
 "name": "Software Maintenance & IT Support Services Dubai",
 "description": "Expert software maintenance and 24/7 IT support solutions in Dubai. Comprehensive server management, cloud solutions, network security & disaster recovery services.",
 "provider": {
   "@type": "Organization",
   "name": "Dynamic Leo",
   "url": "https://dynamicleo.ae",
   "image": "https://dynamicleo.ae/images/software-maintenance-services-hero.webp",
   "address": {
     "@type": "PostalAddress",
     "addressLocality": "Dubai",
     "addressCountry": "UAE"
   },
   "aggregateRating": {
     "@type": "AggregateRating",
     "ratingValue": "4.9",
     "reviewCount": "40",
     "bestRating": "5"
   }
 },
 "mainEntity": {
   "@type": "Service",
   "name": "Software Maintenance Services",
   "serviceType": "IT Support",
   "areaServed": {
     "@type": "GeoCircle",
     "geoMidpoint": {
       "@type": "GeoCoordinates",
       "latitude": "25.2048",
       "longitude": "55.2708"
     }
   },
   "offers": {
     "@type": "AggregateOffer",
     "offers": [
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Server Management",
           "description": "24/7 server optimization and security management with proactive monitoring"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Cloud Infrastructure",
           "description": "Secure cloud storage and infrastructure management solutions"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Access Management",
           "description": "Advanced user authentication and access control systems"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Network Security",
           "description": "Real-time network monitoring and threat detection"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Disaster Recovery",
           "description": "Enterprise backup solutions and disaster recovery planning"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "System Administration",
           "description": "Professional system configuration and performance optimization"
         }
       }
     ]
   },
   "hasOfferCatalog": {
     "@type": "OfferCatalog",
     "name": "Support Features",
     "itemListElement": [
       {
         "@type": "OfferCatalog",
         "name": "24/7 Support",
         "description": "Round-the-clock technical support"
       },
       {
         "@type": "OfferCatalog",
         "name": "Proactive Monitoring",
         "description": "Continuous system monitoring and maintenance"
       },
       {
         "@type": "OfferCatalog",
         "name": "Security Services",
         "description": "Advanced security protocols and protection"
       }
     ]
   }
 },
 "image": [
   "https://dynamicleo.ae/images/software-maintenance-services-hero.webp",
   "https://dynamicleo.ae/images/it-support-services-background.webp",
   "https://dynamicleo.ae/images/server-management-service.webp",
   "https://dynamicleo.ae/images/cloud-infrastructure-solutions.webp",
   "https://dynamicleo.ae/images/access-control-security.webp",
   "https://dynamicleo.ae/images/network-security-monitoring-icon.webp",
   "https://dynamicleo.ae/images/disaster-recovery-backup-icon.webp",
   "https://dynamicleo.ae/images/system-administration-support-icon.webp"
 ],
 "breadcrumb": {
   "@type": "BreadcrumbList",
   "itemListElement": [{
     "@type": "ListItem",
     "position": 1,
     "name": "Home",
     "item": "https://dynamicleo.ae"
   },
   {
     "@type": "ListItem",
     "position": 2,
     "name": "Services",
     "item": "https://dynamicleo.ae/services"
   },
   {
     "@type": "ListItem",
     "position": 3,
     "name": "Software Maintenance",
     "item": "https://dynamicleo.ae/services/software-maintenance"
   }]
 }
}
  `}
        </script>
      </Helmet>
            <Hero />
            <Work />
        </>
    )
}

export default DomainHosting