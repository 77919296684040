import React, { useEffect } from 'react';
import clientsData from '../../Data/Clients';
import AOS from 'aos';
import 'aos/dist/aos.css';
function Clients() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  

  const firstHalf = clientsData.slice(0, clientsData.length / 2);
  const secondHalf = clientsData.slice(clientsData.length / 2);
  return (
    <div className="pb-12 mt-4">
      <div className="sectionlayout my-12">
        <h2
          className="font-bold text-[#C39037] drop-shadow-lg py-14 text-center"
          style={{ fontSize: "clamp(32px,2.4vw,36px)" }}
        >
          Our Partners in Tech
        </h2>
        <div data-aos="fade-up">
        <div className="relative overflow-hidden pt-6">
        <div className="flex gap-8 animate-scroll">
          {[...firstHalf, ...firstHalf].map((logo, index) => (
            <div key={index} className="w-40 flex-shrink-0 flex items-center justify-center">
              <img
                src={logo.img}
                alt={`Client Logo ${index + 1}`}
                className="w-full h-auto object-contain"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="relative overflow-hidden pt-6 mb-8">
        <div className="flex gap-8 animate-scroll-reverse">
          {[...secondHalf, ...secondHalf].map((logo, index) => (
            <div key={index} className="w-40 flex-shrink-0 flex items-center justify-center">
              <img
                src={logo.img}
                alt={`Client Logo ${index + 13}`}
                className="w-full h-auto object-contain"
              />
            </div>
          ))}
        </div>
      </div>
      <style jsx="true">{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        @keyframes scroll-reverse {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(0);
          }
        }

        .animate-scroll {
          display: flex;
          animation: scroll 25s linear infinite;
        }

        .animate-scroll-reverse {
          display: flex;
          animation: scroll-reverse 25s linear infinite;
        }
      `}</style>
        </div>

        <div className="mt-10 flex justify-center items-center w-full relative">
      <img src={`${process.env.PUBLIC_URL}/Assets/Home/srtip-sharjah-innovation-park-partner.webp`} alt="srtip-sharjah-innovation-park-partner" />
<a href="https://dynamicleo.ae/" target='_blank' rel="noreferrer" className=' z-10 w-[200px] md:w-[360px] max-w-full h-[50px] sm:h-[90px] md:h-[116px] cursor-pointer top-16 sm:top-24 md:top-28 lg:top-36 xl:top-48 absolute overflow-x-hidden'>
   
      </a>

      <a href="https://srtip.ae/" target='_blank' rel="noreferrer" className=' z-10 w-[200px] md:w-[380px]   max-w-full h-[90px] sm:h-[120px] md:h-[216px] cursor-pointer bottom-20 md:bottom-32  xl:bottom-36 absolute overflow-x-hidden'>
      </a>
      </div>
      </div>

     
    </div>
  );
}

export default Clients;
