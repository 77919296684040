import React from "react";
import Hero from "./Hero";
import Work from "./Work";
import { Helmet } from "react-helmet";

function DigitalMarketing() {
  return (
    <>
      <Helmet>
        <title>
          Web Hosting & Domain Services UAE | AWS Cloud Solutions Dubai
          Description
        </title>
        <meta
          name="description"
          content="Secure your domain and hosting with UAE's trusted provider. Enterprise-grade cloud hosting, SSL security, and email solutions with 24/7 technical support"
        />

        <script type="application/ld+json">
          {`
    {
     "@context": "https://schema.org",
 "@type": "WebPage",
 "name": "Domain & Web Hosting Services UAE",
 "description": "Secure your domain and hosting with UAE's trusted provider. Enterprise-grade cloud hosting, SSL security, and email solutions with 24/7 technical support.",
 "provider": {
   "@type": "Organization",
   "name": "Dynamic Leo",
   "url": "https://dynamicleo.ae",
   "image": "https://dynamicleo.ae/images/domain-hosting-services-hero-dubai.webp",
   "address": {
     "@type": "PostalAddress",
     "addressLocality": "Dubai",
     "addressCountry": "UAE"
   },
   "aggregateRating": {
     "@type": "AggregateRating",
     "ratingValue": "4.9",
     "reviewCount": "45",
     "bestRating": "5"
   }
 },
 "mainEntity": {
   "@type": "Service",
   "name": "Domain & Hosting Services",
   "serviceType": "Web Hosting",
   "areaServed": {
     "@type": "GeoCircle",
     "geoMidpoint": {
       "@type": "GeoCoordinates",
       "latitude": "25.2048",
       "longitude": "55.2708"
     }
   },
   "offers": {
     "@type": "AggregateOffer",
     "offers": [
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Domain Registration",
           "description": "Premium domain registration services for UAE businesses with TLD options"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Cloud Web Hosting",
           "description": "High-performance cloud hosting powered by AWS and CloudFlare"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Website Migration",
           "description": "Zero-downtime website migration with expert technical handling"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "SSL Security",
           "description": "Advanced SSL certificate implementation for secure data transmission"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Business Email Hosting",
           "description": "Professional email solutions with custom domain integration"
         }
       },
       {
         "@type": "Offer",
         "itemOffered": {
           "@type": "Service",
           "name": "Technical Support",
           "description": "24/7 expert support team for immediate assistance"
         }
       }
     ]
   },
   "hasOfferCatalog": {
     "@type": "OfferCatalog",
     "name": "Hosting Features",
     "itemListElement": [
       {
         "@type": "OfferCatalog",
         "name": "Cloud Infrastructure",
         "description": "AWS and CloudFlare powered hosting"
       },
       {
         "@type": "OfferCatalog",
         "name": "Uptime Guarantee",
         "description": "99.99% uptime guaranteed"
       },
       {
         "@type": "OfferCatalog",
         "name": "Security Features",
         "description": "SSL certificates and advanced security protocols"
       }
     ]
   }
 },
 "image": [
   "https://dynamicleo.ae/images/domain-hosting-services-hero-dubai.webp",
   "https://dynamicleo.ae/images/hosting-services-background.webp",
   "https://dynamicleo.ae/images/domain-registration-service-icon.webp",
   "https://dynamicleo.ae/images/cloud-hosting-service-use-icon.webp",
   "https://dynamicleo.ae/images/website-migration-uae-service-icon.webp",
   "https://dynamicleo.ae/images/ssl-security-service-icon.webp",
   "https://dynamicleo.ae/images/business-email-hosting-icon.webp",
   "https://dynamicleo.ae/images/technical-support-service-icon.webp"
 ],
 "breadcrumb": {
   "@type": "BreadcrumbList",
   "itemListElement": [{
     "@type": "ListItem",
     "position": 1,
     "name": "Home",
     "item": "https://dynamicleo.ae"
   },
   {
     "@type": "ListItem",
     "position": 2,
     "name": "Services",
     "item": "https://dynamicleo.ae/services"
   },
   {
     "@type": "ListItem",
     "position": 3,
     "name": "Domain & Hosting",
     "item": "https://dynamicleo.ae/services/domain-hosting"
   }]
 }
  `}
        </script>
      </Helmet>
      <Hero />
      <Work />
    </>
  );
}

export default DigitalMarketing;
