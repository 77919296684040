

const DMWork = [
  {
    img:`${process.env.PUBLIC_URL}/Assets/solutions/DigitalMarketing/seo-services-dubai-uae.webp`,
    title: "Advanced SEO Solutions",
    alt: "Search Engine Optimization (SEO)",
    descrition:" Dominate search rankings with comprehensive SEO strategies. Technical optimization, content enhancement, and backlink building using industry-leading tools like Ahrefs, Moz, and Google Analytics.",
  },
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/DigitalMarketing/social-media-marketing-uae-services.webp`,
    title: "Social Media Marketing and Advertisement",
    alt: "Social Media Marketing",
    descrition:"Strategic social media management across Facebook, Instagram, LinkedIn, and TikTok. Drive engagement through targeted UAE campaigns and data-driven content optimization.",
    platforms: "Meta Ads Manager, Linkedin Marketing, Tiktok Marketing",
  },
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/DigitalMarketing/content-marketing-strategy-services.webp`,
    title: "Content Marketing Excellence",
    alt: "Content Marketing",
    descrition:"Compelling storytelling that converts. From blog posts to infographics, we create SEO-optimized content using tools like SEMRUSH and HubSpot. Build authority with valuable content that ranks and engages.",
  },
]
const DMWork2 = [

  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/DigitalMarketing/email-marketing-automation-services.webp`,
    title: "Result-Driven Email Marketing",
    alt: "Email Marketing",
    descrition:"Personalized email campaigns that convert. Leverage Mailchimp, Klaviyo, and ActiveCampaign for automated workflows, segmented campaigns, and detailed performance tracking. Boost open rates and drive sales.",
  },
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/DigitalMarketing/pay-per-click-advertising-services.webp`,
    title: "Strategic PPC Campaigns",
    alt: "PPC",
    descrition:"Maximize ROI with targeted Google Ads and UAE-focused paid campaigns. Expert management of search, display, and remarketing campaigns across Google, Bing, and local ad networks.",
  },
  {
    img: `${process.env.PUBLIC_URL}/Assets/solutions/DigitalMarketing/intelligent-analytics-reporting-services.webp`,
    title: "Intelligent Analytics & Reporting",
    alt: "Analytics and Reporting",
    descrition:"Transform data into actionable insights using Google Analytics 4, Google Tag Manager, and custom reporting dashboards. Track KPIs, measure ROI, and optimize performance across all channels.",
  },
];

const digitalMarketingWorks = { DMWork, DMWork2 };

export default digitalMarketingWorks;
