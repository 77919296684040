import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './style.css';

function Blogs() {
  const [featuredBlog, setFeaturedBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); 
  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  useEffect(() => {
    AOS.init({ duration: 1500 });
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`https://blogs.dynamicleo.ae/get-all-blogs`);
      const fetchedBlogs = response.data.data;

      if (fetchedBlogs.length > 0) {
        setFeaturedBlog(fetchedBlogs[0]);
        setBlogs(fetchedBlogs.slice(1));
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <div className='sectionlayout'>
        {loading ? (

          <div className="pulse-loader-container">
            <div className="pulse-bar"></div>
            <div className="pulse-bar"></div>
            <div className="pulse-bar"></div>
          </div>
        ) : (
          <div className="mb-16">
            <div className="sectionlayout py-10 overflow-hidden">
              <h2 className="font-bold poppins uppercase text-[#666666] drop-shadow-lg py-14 text-center"
                style={{ fontSize: "clamp(40px,3.5vw,50px)" }}
                data-aos="fade-up"
              >
                Our Blogs
              </h2>

              {featuredBlog && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center justify-center">
                  <div>
                    <Link to={`/blog?id=${featuredBlog.id}&title=${featuredBlog.title}`} onClick={handleNav}>
                      <img
                        src={`https://blogs.dynamicleo.ae/public/uploads/${featuredBlog.photo}`}
                        alt={featuredBlog.title}
                        className="w-full h-[300px] xl:h-[350px] 2xl:h-[500px] rounded-xl"
                        data-aos="fade-right"
                      />
                    </Link>
                  </div>
                  <Link to={`/blog?id=${featuredBlog.id}&title=${featuredBlog.title}`} onClick={handleNav}>
                    <div data-aos="fade-left">
                      <div className="flex items-center my-2 text-[#666666] font-medium"
                        style={{ fontSize: "clamp(14px,0.8vw,17px)" }}
                      >
                        <span>{featuredBlog.date}</span>
                      </div>
                      <h2 className="text-2xl font-bold mb-2 hover:underline poppins text-[#444444]"
                        style={{ fontSize: "clamp(24px,2.1vw,32px)" }}
                      >
                        {featuredBlog.title}
                      </h2>
                      <p className="text-[#666666]" style={{ fontSize: "clamp(18px,1.1vw,22px)" }}>
                        {featuredBlog.short_description}
                      </p>
                    </div>
                  </Link>
                </div>
              )}

              {/* Blog List */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-5 min-h-[20vh] pb-24 mt-10">
                {blogs.map((blog, index) => (
                  <div className="w-full mb-4 md:mb-0" key={index}>
                    <Link to={`/blog?id=${blog.id}&title=${blog.title}`} onClick={handleNav}>
                      <div className="bg-white h-full flex flex-col justify-between rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 ease-in-out overflow-hidden"
                        data-aos="fade-up"
                      >
                        <img
                          src={`https://blogs.dynamicleo.ae/public/uploads/${blog.photo}`}
                          alt={blog.title}
                          className="w-full h-[300px]"
                        />
                        <div className="p-4 flex-grow">
                          <div className="flex items-center my-2 text-[#666666] font-medium"
                            style={{ fontSize: "clamp(14px,0.8vw,17px)" }}
                          >
                            <span>{blog.date}</span>
                          </div>
                          <h2 className="text-2xl font-bold mb-2 hover:underline poppins text-[#444444]"
                            style={{ fontSize: "clamp(24px,2.1vw,32px)" }}
                          >
                            {blog.title}
                          </h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Blogs;
