const Aibased = [
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/AiBased/AiIntegration.webp`,
        title: "AI Integration",
        alt:"AI Integration",
        descrition: "Seamless integration of AI systems into your existing infrastructure. Custom implementation of machine learning models and intelligent workflows. Enhanced decision-making through automated processes and smart analytics."

    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/AiBased/AiMobileDevelopment.webp`,
        title: "AI Model Development",
        alt:"AI Model Development",
        descrition: "Custom AI model development tailored to your business needs. From data preprocessing to model deployment, we build scalable solutions. Leverage deep learning and predictive analytics for actionable insights."
    },
   
    
   
    {
        img:  `${process.env.PUBLIC_URL}/Assets/solutions/AiBased/LLM.webp`,
        title: "LLM Development",
        alt:"LLM Development",
        descrition: "Custom Language Model development for specialized business applications. Fine-tuning and optimization of large language models for specific use cases. Secure implementation with focus on accuracy and performance."
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/solutions/AiBased/Nlp.webp`,
        title: "NLP Development",
        alt:"NLP Development",
        descrition: "Advanced Natural Language Processing solutions for text analysis and understanding. Intelligent document processing and sentiment analysis implementations. Custom NLP models for automated content processing and insights."

    },
    {
        img:  `${process.env.PUBLIC_URL}/Assets/solutions/AiBased/Chatbot.webp`,
        title: "Chatbot Development",
        alt:"Chatbot Development",
        descrition: "Intelligent conversational AI solutions for customer engagement. Custom chatbot development with natural language understanding. Integration with multiple platforms for seamless communication."

    },
    {
        img:  `${process.env.PUBLIC_URL}/Assets/solutions/AiBased/AiConsultation.webp`,
        title: "AI Consultation",
        alt:"AI Consultation",
        descrition: "Strategic AI roadmap development for business transformation. Expert guidance on AI implementation and technology selection. Data-driven recommendations for maximizing AI investment returns."

    },
]

export default Aibased;