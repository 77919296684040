import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaPhone, FaWhatsapp } from 'react-icons/fa';
import Social from '../../Components/Social/Social'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Footer() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const handleLinkClick = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  return (
    <div className='w-full bg-[#ffffff] pb-5'>
      <div className='w-full sectionlayout poppins' style={{ fontSize: "clamp(14px,0.8vw,16px)" }}>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-[#6c6c6c] py-8'>
          <div data-aos="fade-up">
            <Link to='/' onClick={handleLinkClick}>
              <img src={`${process.env.PUBLIC_URL}/Assets/It-Solutions-Dynamic-Leo.webp`} alt="It-Solutions-Dynamic-Leo" className='max-h-[9vh] max-w-[70%] md:max-h-[60px] md:max-w-[220px]' />
            </Link>
            <p className='mt-4 mr-4 md:mr-8'>Office 21, First Floor, UNIBRICKS Business Center, Salah Al Din Street, Dubai UAE</p>
            <p className='mt-3'>
              <a href="mailto:info@dynamicleo.ae">
                info@dynamicleo.ae
              </a>
            </p>
            <p className='mt-3'>
              <a href="tel:+971509677514" className='block'>
                <FaPhone className='inline-block mr-2 text-[1.1rem]' /> +971 50 967 7514
              </a>
              <a href="https://wa.me/971521205514" target="_blank" rel="noopener noreferrer" className='block mt-3'>
                <FaWhatsapp className='inline-block mr-2 text-[1.4rem]' /> +971 52 120 5514
              </a>
            </p>

          </div>
          <div data-aos="fade-up">
            <h5 className='font-semibold mt-8 md:mt-1 mb-4 relative' style={{ fontSize: "clamp(18px,1.1vw,22px)" }}>Quick Links <span className='absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]'></span></h5>
            <Link to="/" onClick={handleLinkClick}><p className='mt-[35px]'>Home</p></Link>
            <Link to="/solutions" onClick={handleLinkClick}><p className='mt-3'>Solutions</p></Link>
            <Link to="/blogs" onClick={handleLinkClick}><p className='mt-3'>Blogs</p></Link>
            <Link to="/about" onClick={handleLinkClick}><p className='mt-3'>About Us</p></Link>
            <Link to="/contact" onClick={handleLinkClick}><p className='mt-3'>Contact Us</p></Link>
            <Link to="/terms_and_conditions" onClick={handleLinkClick}><p className='mt-3'>Terms & Conditions</p></Link>
            <Link to="/privacy_policy" onClick={handleLinkClick}><p className='mt-3'>Privacy Policy</p></Link>
          </div>
          <div data-aos="fade-up">
            <h5 className='font-semibold  mt-8 lg:mt-1 mb-4 relative' style={{ fontSize: "clamp(18px,1.1vw,22px)" }}>Solutions <span className='absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]'></span></h5>
            <Link to="/digital-marketing" onClick={handleLinkClick}><p className='mt-[35px]'>Digital Marketing</p></Link>
            <Link to="/web-development" onClick={handleLinkClick}><p className='mt-3'>Web Development</p></Link>
            <Link to="/mobile-app-development" onClick={handleLinkClick}><p className='mt-3'>Mobile App Development</p></Link>
            <Link to="/ai-based-solutions" onClick={handleLinkClick}><p className='mt-3'>AI Based Solutions</p></Link>
            <Link to="/domain-and-hosting" onClick={handleLinkClick}><p className='mt-3'>Domain & Hosting</p></Link>
            <Link to="/maintenance-services" onClick={handleLinkClick}><p className='mt-3'>Maintenance Services</p></Link>
          </div>
          <div data-aos="fade-up">
            <h5 className='font-semibold  mt-8 lg:mt-1 mb-4 relative' style={{ fontSize: "clamp(18px,1.1vw,22px)" }}>Subscribe <span className='absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]'></span></h5>
            <div className='md:w-full mt-[45px] flex mb-5'>
              <input type="text" placeholder='Get product updates' className='p-4 w-[80%] border font-normal rounded-tl-[10px] rounded-bl-[10px] focus:border-blue-500 focus:outline-none' />
              <button type='submit' className='p-4 w-[20%] font-normal bg-[#478EFF] flex items-center justify-center rounded-tr-[10px] rounded-br-[10px]'><img src={`${process.env.PUBLIC_URL}/Assets/icons/right_arrow_white.svg`} alt="right_arrow" /></button>
            </div>
            <div className='flex md:block items-center justify-center'>
              <Social />
            </div>
          </div>
        </div>
        <p className="text-[#6c6c6c] text-center md:text-left">© 2024 Dynamic Leo. All rights reserved</p>
      </div>
    </div>
  )
}
export default Footer
