import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./style.css";
import "./blog.css";

function Blog() {
  const navigate = useNavigate();
  const location = useLocation();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const title = queryParams.get("title");

  useEffect(() => {
    if (!id || !title) {
      navigate("/blogs");
      return;
    }

    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://blogs.dynamicleo.ae/get-blog-by-id/${id}`
        );

        if (!response.data.data || response.data.data.length === 0) {
          navigate("/blogs");
          return;
        }

        setBlog(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching the blog:", error);
        navigate("/blogs");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id, title, navigate]);

  return (
    <div className="sectionlayout">
      {loading ? (
        <div className="pulse-loader-container">
          <div className="pulse-bar"></div>
          <div className="pulse-bar"></div>
          <div className="pulse-bar"></div>
        </div>
      ) : blog ? (
        <>
          {/* Blog Content */}
          <div className="overflow-hidden mt-4 flex items-center justify-center">
            <img
              src={`https://blogs.dynamicleo.ae/public/uploads/${blog.photo}`}
              alt={blog.title}
              className="h-[500px] w-[600px] 2xl:h-[600px] 2xl:w-[800px]"
            />
          </div>
          <div className="py-6 flex flex-col items-start">
            <span className="block text-[#666666] text-lg">{blog.date}</span>
            <h2
              className="font-bold poppins uppercase text-[#333333] py-4"
              style={{ fontSize: "clamp(30px,2.2vw,45px)" }}
            >
              {blog.title}
            </h2>
          </div>
          <div
            className="mx-auto px-4 md:px-0 poppins text-[#333333]"
            dangerouslySetInnerHTML={{ __html: blog.description }}
          />
          <style jsx="true">{`
            a {
              color: rgb(0, 136, 255) !important;
              transition: all 0.3s ease-in-out
            }
            a:hover {
              text-decoration: underline !important;
             color: rgb(0, 64, 255) !important;
            }
          `}</style>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Blog;
