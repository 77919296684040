const Home = [
    {
        img: `${process.env.PUBLIC_URL}/Assets/Home/strategic-digital-marketing-services.webp`,
        title: "Digital Marketing",
        alt: "DigitalMarketing",
        description: "Strategic digital campaigns that transform UAE businesses online. Expert SEO, social media management, and targeted advertising solutions that deliver measurable ROI. Data-driven strategies for market leadership.",
        link: "/digital-marketing"
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/Home/custom-website-development-services.webp`,
        title: "Website Development",
        alt: "WebDevelopment",
        description: "Professional websites and web applications built for UAE businesses. Responsive design, seamless functionality, and scalable solutions that drive growth. From corporate websites to e-commerce platforms, we deliver results.",
        link: "/web-development"
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/Home/mobile-app-development-ios-android.webp`,
        title: "Mobile App Development",
        alt: "AppDevelopment",
        description: "Native iOS and Android applications that transform UAE businesses. Expert development of feature-rich mobile solutions that put your services in your customers' hands. From concept to launch, we build apps that engage and convert.",
        link: "/app-development"
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/Home/artificial-intelligence-machine-learning-solutions.webp`,
        title: "AI & Machine Learning Solutions",
        alt: "AIBasedSolutions",
        description: "Transform your business operations with powerful AI and machine learning solutions. Our custom implementations automate processes, analyze data, and provide actionable insights. Enhance efficiency and decision-making with intelligent technologies.",
        link: "/ai-based-solutions"
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/Home/web-hosting-domain-management-services.webp`,
        title: "Domain & Hosting ",
        alt: "DomainHosting",
        description: "Premium domain and hosting solutions for UAE businesses powered by AWS and CloudFlare. Secure SSL certificates, cloud hosting, and CDN integration with 99.9% uptime guarantee. Expert support for seamless domain management.",
        link: "/domain-and-hosting"
    },
    {
        img: `${process.env.PUBLIC_URL}/Assets/Home/software-maintenance-support-services.webp`,
        title: "Software Maintenance",
        alt: "Maintenance",
        description: "Professional software maintenance and 24/7 technical support ensuring business continuity. Our expert team provides system updates, performance monitoring, and proactive issue resolution for all your applications.",
        link: "/maintenance-services"
    }
]

export default Home;
